import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import CalibrateRightRoundOne from "../images/CalibrateRightRoundOne.png"
import CalibrateRightRoundTwo from "../images/CalibrateRightRoundTwo.svg"
import Shapefour from "../images/bannerElement/white-design-element-calibrate.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import BusinessfocusedVisualization from "../images/icons/ArenaCalibrate/BusinessfocusedVisualization.svg"
import DataGovernanceandIntegration from "../images/icons/ArenaCalibrate/DataGovernanceandIntegration.svg"
import EnterpriseLevelDataAndAnalytics from "../images/icons/ArenaCalibrate/EnterpriseLevelDataAndAnalytics.svg"
import OneSourceofTruth from "../images/icons/ArenaCalibrate/OneSourceofTruth.svg"
import RevenueImpactMarketing from "../images/icons/ArenaCalibrate/RevenueImpactMarketing.svg"
import "../styles/arena-calibrate.css"

export default function AccountBasedMarketing() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Arena | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="CalibrateStyles">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>Arena.ai</h1>
                    <p>
                      Transform the way you analyze data to make smarter
                      business decisions, at speed.
                    </p>
                    <a className="button" id="3dsolutionvideo" href="https://calibrate.thearena.ai/" target="_blank">Know more</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap"> 
                  <h2>
                    Harness the full potential of your data, analytics, and
                    insights at scale
                  </h2>
                  <p>
                    Maximize your business's success, unearth invaluable data
                    insights, and supercharge your strategies with tailor-made
                    enterprise-grade analytics solutions.
                  </p>
                  <a className="button" id="3dsolutionvideo" href="https://calibrate.thearena.ai/" target="_blank">Know more</a>
                </div>
                <div class="img-wrap">
                  <img alt="Calibrate" src={CalibrateRightRoundOne} />
                </div>
              </div>
            </div>
          </section>
          <section id="WeDeliver">
            <div className="container">
              <h2>Our enterprise-level analytics capabilities</h2>
              <p>
                Arena provides end-to-end intelligence support to maximize your
                analytics potential. We’ll handle data integration, new
                dashboard creation, dashboard customizations, new data sources,
                so you can arrive at your ideal vision.
              </p>
              <div className="horizontalImageIconSec">
                <div className="moduleWrap">
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={EnterpriseLevelDataAndAnalytics}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Enterprise-level Data and Analytics</h3>
                      <p>
                        Offers scalable, adaptable software with comprehensive
                        BI services for unified analytics.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={BusinessfocusedVisualization}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Business-focused Visualization</h3>
                      <p>
                        Delivers efficient insights through expert-driven
                        transformations, bypassing self-service reporting
                        limitations.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={OneSourceofTruth} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>One Source of Truth</h3>
                      <p>
                        Eliminate data silos and unify analytics across
                        platforms.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={RevenueImpactMarketing}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Revenue Impact Marketing</h3>
                      <p>
                        Identifies marketing's real contribution to sales,
                        leads, and revenue through advanced analytics.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={DataGovernanceandIntegration}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Data Governance and Integration</h3>
                      <p>
                        Simplifies syncing across 60+ platforms and manual
                        integrations, ensuring data accuracy and proper
                        configuration.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="TextImageSection" class="SecondTextImage">
            <div class="holder">
              <div class="container">
                <div class="text-image-holder">
                  <div class="text-wrap">
                    <div class="animation-text">
                      <span class="quote"></span>
                      <div>
                        <p>
                          Saves me a lot of time that I used to spend manually
                          collecting reports. I like the insights feature which
                          lists keywords that are not performing, and the
                          quality score report. I can also drill down into
                          charts and go deeper into analyzing campaign
                          performance.
                        </p>
                      </div>
                      <div class="testi-designation">
                        <p class="designation">
                          <strong>
                            Gwendoline Drouin
                            <br />
                          </strong>
                          The Hotels Network
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="img-wrap">
                    <img alt="Copy write" src={CalibrateRightRoundTwo} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>See if Arena is the right solution for you</h2>
              <a className="button" id="3dsolutionvideo" href="/contact-us/">Get in touch</a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
